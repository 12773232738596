@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  display: none;
}

input[type="password"]::-ms-reveal {
  display: none;
}
